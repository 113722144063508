import React from "react";
import { Link } from "gatsby";
import styled from 'styled-components';
import DateFormatter from "./DateContainer";
import { Wrapper, Flex, TitleLink, Position, PrintOnlyLink } from "../templates/Index";
import { DateFields } from "./DateFields";
import divList from './DivList';
import commaList from './CommaList';

const Body = styled.div`
  padding-left: 10px;
  margin: 12px 0;
`

export const Description = styled.div`
  page-break-inside: avoid;
  page-break-after: auto;
  page-break-before: auto;
  orphans: 2;
  widows: 2;
  font-size: 10pt;
  margin: 6px 0;
`;

const TechStack = styled.div`
display: flex;
align-items: flex-start;
font-style: italic;
`
const SkillsStack = styled(TechStack)`
margin-top: 5px;
`
const SkillsStackChild = styled(SkillsStack)`
margin-top: 0;
`


const StackTitle = styled.h4`
min-width: 35px;

margin: 0;
font-weight: normal;
`
const DoubleStackTitle = styled(StackTitle)`
min-width: 70px;
`

const BlockSpan = styled.span`
display: block;
`

const QuickThought = styled.div`
font-style: italic;
font-size: 9pt;
padding-left: 10px;
align-self: center;
`
export const WorkItem = ({
  title, position, startDate, endDate, description, slug, shortUrl, hideOnPrint, 
  roles, techStack, techSkills, softSkills, projects, quickThought
}) => {
  return (
    <Wrapper hideOnPrint={hideOnPrint}>
      <Flex>
        <TitleLink {...(slug ? {to: slug} : {to: ''})} >
          {title}
        </TitleLink>
        <Position>
          - {position}
        </Position>
        {/* <PrintOnlyLink>
          <Link {...(slug ? {to: slug} : {to: ''})} >{shortUrl}</Link>
        </PrintOnlyLink> */}
      </Flex>
      <Flex>
      <DateFields>
        <DateFormatter date={startDate} /> -{" "}
        <DateFormatter nullValue="Current" date={endDate} />
      </DateFields>
      <QuickThought>{quickThought}</QuickThought>
      </Flex>
      <Body>
      {techStack && techStack.length && <TechStack>
        <DoubleStackTitle>Tech Stack:</DoubleStackTitle>
        <div>{divList(techStack)}</div>
      </TechStack>}

      {(techSkills || softSkills) && 
      <SkillsStack>
          <StackTitle>Skills</StackTitle>
          <div>
          {techSkills && techSkills.length && <SkillsStackChild>
            <StackTitle>
              <BlockSpan> tech:</BlockSpan>
            </StackTitle>
            <div>{commaList(techSkills)}</div>
          </SkillsStackChild>} 

          {softSkills && softSkills.length && <SkillsStackChild>
            <StackTitle>
              <BlockSpan> soft:</BlockSpan> 
            </StackTitle>

            <div>{commaList(softSkills)}</div>
          </SkillsStackChild>}
          </div>
      </SkillsStack>}


      <Description>{description}</Description>
      </Body>
    </Wrapper>
  );
};
