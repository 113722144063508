import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import { Link } from "gatsby";

import ContentMDX from "../components/ContentMDX";
import Layout from "../components/Layout";
import Section from '../components/Section';
import { WorkItem } from "../components/WorkItem";




const Attendance = styled.div`
display: inline-block;
`

//export const TitleLink = styled(Link)``

export const TitleLink = styled('div')`
  @media screen and (max-width: 7.9in) {
    & {
      margin-bottom: 5px 0;
    }
  }
  display: inline-flex;
  flex-grow: 1;
  letter-spacing: 1px;
  font-size: 12pt;
  text-decoration: none;
  color: inherit;
  /* &:visited {
    color: inherit;
  }
  &:hover {
    color: var(--accent-color);
    text-decoration: underline;
  } */
`;
export const Flex = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

const PrintOnlyHeader = styled.div`
  display: none;

  @media print {
    & {
      font-size: 10pt;
      min-width: 1in;
      /* display: block; */
    }
  }
`;

export const PrintOnlyLink = styled.div`
  display: none;

`;
export const Position = styled.div`
display: inline-block;
`
export const Wrapper = styled.div`
  page-break-before: auto;
  page-break-inside: avoid;
  padding-bottom: 5px;

  @media print {
    ${({ hideOnPrint = false }) => {
    return (hideOnPrint ? `display: none;` : "")
  }
  }
  }

  @media screen {
    & {
      border-bottom: 2px solid var(--primary-color);
      border-image: -webkit-gradient(
          linear,
          left bottom,
          right bottom,
          from(var(--primary-color)),
          to(var(--background-color)),
          color-stop(0.25, var(--background-color))
        )
        31 30 30 31 repeat repeat;
      border-image-repeat: stretch;
      margin-bottom: 20px;
    }
  }
  @media screen and (max-width: 7.9in) {
    & {
      margin: 30px 0;
      border-bottom: 2px solid var(--primary-color);
      border-image: -webkit-gradient(
          linear,
          left bottom,
          right bottom,
          from(var(--primary-color)),
          to(var(--background-color)),
          color-stop(0.5, var(--background-color))
        )
        31 30 30 31 repeat repeat;
      border-image-repeat: stretch;
    }
  }
`;

const FlexTitle = styled.div`
  display: flex;
  align-items: center;
`;
// const Section = styled.section`
//   margin-top: 10px;
//   margin-bottom: 30px;
// `;

const SectionTitle = styled.h2`
  flex-grow: 1;
  font-size: 12pt;
  text-transform: uppercase;
  margin: 0 0 6pt;
  /* border-bottom: 2px solid var(--primary-color); */
  color: var(--primary-color);
  border-image: -webkit-gradient(
      linear,
      left bottom,
      right bottom,
      from(var(--primary-color)),
      to(var(--background-color)),
      color-stop(0.75, var(--background-color))
    )
    31 30 30 31 repeat repeat;
  border-image-repeat: stretch;
`;
const WorkHistory = ({ history }) => (
  <Section title="Work Experience">
    <FlexTitle>
      <PrintOnlyHeader>For addl. info visit</PrintOnlyHeader>
    </FlexTitle>
    <div>
      {history.map(workItem => (
        <WorkItem key={workItem.slug} {...workItem} />
      ))}
    </div>
  </Section>
);



const Index = ({ data: { page, work, conference } }) => {
  const workHistory = (work.edges || []).map(
    workItem => workItem.node.frontmatter
  );
  const conferenceHistory = (conference.edges || []).map(
    workItem => workItem.node.frontmatter
  );

  console.log(work);
  return (
    <Layout conferenceHistory={conferenceHistory} {...page.frontmatter}>
      <ContentMDX source={page.body} />
      <WorkHistory history={workHistory} />
    </Layout>
  );
};

export default Index;

export const pageQuery = graphql`
  query Index($id: String!) {
    page: mdx(id: { eq: $id }) {
      body
      frontmatter {
        title
        subtitle
        profile
        position
        startDate
        endDate
        shortUrl
        featuredImage
      }
    }

    work: allMdx(
      filter: { frontmatter: { template: { eq: "WorkMDX" } } }
      sort: {
        order: DESC
        fields: [
          frontmatter___startDate
          frontmatter___endDate
        ]
      }
    ) {
      edges {
        node {
          body
          frontmatter {
            slug
            title
            subtitle
            profile
            roles
            techStack
            techSkills
            softSkills
            quickThought
            projects
            position
            startDate
            endDate
            shortUrl
            featuredImage
            description
            hideOnPrint
          }
        }
      }
    }
    conference: allMdx(
      filter: { frontmatter: { template: { eq: "ConferenceMDX" } } }
      sort: { order: DESC, fields: [frontmatter___startDate] }
    ) {
      edges {
        node {
          body
          frontmatter {
            slug
            title
            position
            startDate
            attendanceType
            shortUrl
            featuredImage
            description
          }
        }
      }
    }
  }
`;
